import { Link } from "gatsby"
import React from "react"

export const PageBanner = ({ isHomePage, pageInfo }) => {
  if (isHomePage) return

  const pageBanner =
    isHomePage === false
      ? require("../../images/banner/banner_pagini_linda.png").default
      : ""
  return (
    <div
      className="dlab-bnr-inr overlay-black-middle bg-pt"
      style={{ backgroundImage: "url(" + pageBanner + ")" }}
    >
      <div className="container">
        <div className="dlab-bnr-inr-entry">
          <h1 className="text-white">{pageInfo.title}</h1>
          <div className="breadcrumb-row">
            <ul className="list-inline">
              <li>
                <Link to="/">Acasa</Link>
              </li>
              {pageInfo.hasParent && (
                <li>
                  <Link to={`${pageInfo.parent.slug}`}>{pageInfo.parent.title}</Link>
                </li>
              )}
              <li>{pageInfo.title}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
