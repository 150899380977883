import React from "react"
import "./contact.css"
import "../../plugins/bootstrap/css/bootstrap.css"
import ContactForm from "../Forms/ContactForm"

const email =
  require("../../assets/contact-assets/Icon_feather-mail.png").default
const phone =
  require("../../assets/contact-assets/Icon_feather-phone.png").default

function Contact() {

  return (
    <>
      <div className="page-content bg-white contact-page-content">
        <div className="th-contact-wrapper">
          <div className="th-contact-info">
            <div className="th-contact-info__item">
              <img
                src={`${email}`}
                alt="Linda - Email"
                style={{
                  fontSize: "48px",
                }}
              />
              <strong>EMAIL</strong>
              <a href="mailto:office@aegcom.eu"><span>office@aegcom.eu</span></a>
            </div>
            <div className="th-contact-info__item">
              <img
                src={`${phone}`}
                alt="Linda - Telefon"
                style={{
                  fontSize: "48px",
                }}
              />
              <strong>TELEFON</strong>
              <div className="th-contact-info__sub-item"><strong>AEG:&nbsp;</strong><a href="tel:0749106622">0749106622</a></div>
              <div className="th-contact-info__sub-item"><strong>REDANS:&nbsp;</strong><a href="tel:0749106622">0731644758</a></div>
              <div className="th-contact-info__sub-item"><strong>INOSOFT:&nbsp;</strong><a href="tel:0749106622">0743264952</a></div>
            </div>
          </div>

          <ContactForm />
        </div>
        <div className="th-google-maps-wrapper">
          <iframe
            title="LinDA Google Maps"
            width="100%"
            height="100%"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            id="gmap_canvas"
            src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=AEG COMMISSIONING&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
        </div>
      </div>
    </>
  )
}

export default Contact
