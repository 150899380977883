import React from 'react'
import "./styles.css"
// import "../../plugins/bootstrap/css/bootstrap.css"
import "../../../plugins/bootstrap/css/bootstrap.css"
import { useState, useRef } from "react"

const axios = require("axios").default

function ContactForm() {

    const [contactData, setContactData] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        oferta: "Partener",
        message: "",
        companyName: "",
    })


    const form = useRef()

    const resetForm = () => {
        setContactData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
            oferta: "",
            companyName: "",
        })
    }

    const sendEmail = e => {
        e.preventDefault()

        axios
            .post(`${process.env.STRAPI_ENDPOINT}/emails`, {
                data: {
                    Nume: contactData.name,
                    Email: contactData.email,
                    Telefon: contactData.phone,
                    Subiect: contactData.subject,
                    NumeCompanie: contactData.companyName,
                    Oferta: contactData.oferta,
                    Mesaj: contactData.message,

                }
            }, {
                headers: {
                    Authorization: `Bearer ${process.env.API_KEY}`,
                }
            })
            .then(response => {
                console.log(response)
                alert("Formularul s-a trimis cu succes!")
            })
            .catch(err => {
                alert("S-a produs o eroare. Va rugam sa incercati din nou")
                console.log("subscribe error -- ", err)
            })

        resetForm()
    }

    return (
        <div className="th-contact__form-wrapper">
            <form
                ref={form}
                className="th-contact-form"
                method="POST"
                onSubmit={sendEmail}
            >
                <h2>Formular de Contact</h2>
                <div className="th-row">
                    <input
                        required={true}
                        type="text"
                        className="form-control"
                        placeholder="Nume"
                        name="name"
                        value={contactData.name}
                        onChange={e =>
                            setContactData({ ...contactData, name: e.target.value })
                        }
                    />

                    <input
                        required={true}
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        aria-describedby="emailHelp"
                        name="email"
                        value={contactData.email}
                        onChange={e =>
                            setContactData({ ...contactData, email: e.target.value })
                        }
                    />
                </div>

                <div className="th-row">
                    <input
                        required={true}
                        type="text"
                        className="form-control"
                        placeholder="Telefon"
                        name="phone"
                        value={contactData.phone}
                        onChange={e =>
                            setContactData({ ...contactData, phone: e.target.value })
                        }
                    />
                    <input
                        required={true}
                        type="text"
                        className="form-control"
                        placeholder="Subiect"
                        name="subject"
                        value={contactData.subject}
                        onChange={e =>
                            setContactData({ ...contactData, subject: e.target.value })
                        }
                    />
                </div>

                <div className="th-row">
                    <input
                        required={true}
                        type="text"
                        className="form-control"
                        placeholder="Nume Companie"
                        name="companyName"
                        value={contactData.companyName}
                        onChange={e =>
                            setContactData({ ...contactData, companyName: e.target.value })
                        }
                    />
                </div>
                <legend>Oferta</legend>
                <div className="d-block">
                    <div className="form-check form-check-inline">
                        <input
                            checked={contactData.oferta === "Partener" ? true : false}
                            className="form-check-input"
                            onChange={e =>
                                setContactData({ ...contactData, oferta: "Partener" })
                            }
                            type="radio"
                            name="oferta"
                            id="partener"
                        />
                        <label className="form-check-label" htmlFor="partener">
                            Partener
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            checked={contactData.oferta === "Integrator" ? true : false}
                            className="form-check-input"
                            onChange={e =>
                                setContactData({ ...contactData, oferta: "Integrator" })
                            }
                            type="radio"
                            name="oferta"
                            id="integrator"
                        />
                        <label className="form-check-label" htmlFor="integrator">
                            Integrator
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            checked={contactData.oferta === "End-User" ? true : false}
                            className="form-check-input"
                            onChange={e =>
                                setContactData({ ...contactData, oferta: "End-User" })
                            }
                            type="radio"
                            name="oferta"
                            id="end-user"
                        />
                        <label className="form-check-label" htmlFor="end-user">
                            End-User
                        </label>
                    </div>
                </div>

                <div className="th-row">
                    <textarea
                        required={true}
                        className="form-control"
                        rows="5"
                        placeholder="Mesaj"
                        name="message"
                        value={contactData.message}
                        onChange={e =>
                            setContactData({ ...contactData, message: e.target.value })
                        }
                    ></textarea>
                </div>

                <button type="submit" className="btn btn-primary w-100">
                    Trimite
                </button>
            </form>
        </div>
    )
}

export default ContactForm