import React from "react"
import Contact from "../components/Contact/Contact"
import Layout from "../markup/layout/layout"
import Seo from "../markup/layout/seo"
import { PageBanner } from "../markup/layout/PageBanner"

function contact() {
  const pageTitle = "Contact"
  return (
    <Layout>
      <Seo title={pageTitle} />
      <PageBanner
        pageTitle={pageTitle}
        isHomePage={false}
        pageInfo={{
          title: pageTitle,
          parent: { title: "", slug: "" },
          hasParent: false,
        }}
      />
      <Contact />
    </Layout>
  )
}

export default contact
